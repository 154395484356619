import React from 'react';

import styles from './LandPage.module.css';

import BGvideo from '../../assets/video/BGVideo.mov';
import PosterImg from '../../assets/video/poster-bg.png';
import AboutImg from '../../assets/images/pictures/aboutMe-profile.png';

const LandPage = (props) => {
  let isMobile = props.aspectRatio < 1;
  function scrollPosition() {
    let position = -(props.scrollPosition * 4);
    if (position < -100) {
      return -100;
    }
    return position;
  }

  return (
    <main className={styles.container}>
      <div className={styles.textArea}>
        <div className={styles['video-container']}>
          <video src={BGvideo} poster={PosterImg} autoPlay muted loop></video>
        </div>
        <div
          style={{
            paddingLeft: '5%',
            transform: !isMobile && `translateX(${scrollPosition()}%)`,
          }}
          className={`${styles.inner} ${styles.overlayFX}`}
        >
          <div className={styles['hero-text']}>
            <h1>woxensound</h1>
            <h5>tonemester til set - post - udstyrsudlejning</h5>
          </div>
        </div>
        <div style={{ transform: !isMobile && `translateX(${scrollPosition()}%)` }} className={`${styles.inner} ${styles.screenFX}`}>
          <div className={styles['about-container']}>
            <img src={AboutImg} alt="me" />
            <p>
              Uddannet bachelor fra Sonic College og går på Den Danske Filmskole. Med mange års erfarring som freelancer med post og set, kan i hvile trygt ved mine kompetencer.<br></br>
              <br></br> Jeg har egne post faciliteter til web, tv, samt biograf og professionelle lyd-pakker til set optagelser af store spillefilm og alt herunder. Uanset projektets størrelse er i
              altid velkommen til at kontakte mig, så finder vi den løsning, der passer bedst til jer.
            </p>
          </div>
        </div>
      </div>
    </main>
  );
};

export default LandPage;
