import React, { useState, useEffect } from 'react';

import PortInfoTxt from './PortInfoTxt';
import HorizontalScroll from './HorizontalScroll';
import MovieMap from './MovieMap';
import Spotlight from './Spotlight';
import Overlay from './Overlay';

import styles from './PortSlider.module.css';

const PortSlider = ({ movies, isVisible, windowWidth, aspectRatio }) => {
  const [isDrag, setIsDrag] = useState(false);
  const [isMouse, setIsMouse] = useState(true);
  const [scrollAmount, setScrollAmount] = useState(0);
  const [aspectSlide, setAspectSlide] = useState(0);
  const [position, setPosition] = useState(0);

  const [focusId, setFocusId] = useState(3);
  const [hoverId, setHoverId] = useState(3);
  const [spotlight, setSpotlight] = useState(false);

  // Aspect ratio offset handler
  useEffect(() => {
    setPosition(0);
    if (aspectRatio < 1) {
      setAspectSlide(33);
      return;
    } else if (aspectRatio < 1.6) {
      setAspectSlide(19.5);
      return;
    } else {
      setAspectSlide(14);
    }
  }, [aspectRatio]);

  // Scroll offset handler
  useEffect(() => {
    if (isDrag && typeof scrollAmount === 'number') {
      let eRound = Math.round(scrollAmount) / 65;
      if (focusId < movies.length || eRound > 0) {
        if (position > 40 && eRound > 0) return;
        setFocusId(Math.abs(Math.round((position - 44) / aspectSlide)));
        setPosition((prevState) => prevState + eRound);
      }
    } else if (!isDrag && aspectSlide !== 0) {
      let newPosition = Math.round(position / aspectSlide) * aspectSlide;
      newPosition = newPosition || 0;
      setFocusId(Math.abs(Math.round((position - 44) / aspectSlide)));
      setPosition(newPosition);
      setScrollAmount(0);
    }
  }, [scrollAmount, isDrag]);

  // Spotlight on/off handler
  function handleSpotlight(i) {
    if (!isMouse) setHoverId(i);
    if (spotlight) {
      setSpotlight(false);
      return;
    }
    if (isDrag) return;
    setSpotlight(true);
  }

  // If mouse hover handler
  function handleHover(id, event) {
    let hoverType = event._reactName;
    if (hoverType === 'onMouseEnter') {
      setHoverId(id);
    }
  }

  // isMouse Handler
  function inputTypeHandler(e) {
    setIsMouse(e);
  }

  // Drag Handler
  function dragHangler(e) {
    setIsDrag(e);
  }

  return (
    <div className={styles.main}>
      <Spotlight setSpotlight={handleSpotlight} movie={movies[hoverId]} styles={styles} isActive={spotlight} />
      <div className={styles.header}>
        <PortInfoTxt isMouse={isMouse} focusId={isMouse ? hoverId : focusId} movies={movies} styles={styles} />
      </div>
      <HorizontalScroll windowWidth={windowWidth} isVisible={isVisible} setDrag={dragHangler} isMouse={isMouse} setIsMouse={inputTypeHandler} setScrollAmount={setScrollAmount}>
        <Overlay styles={styles} />
        <div style={{ transform: `translateX(${position}vw)`, gap: aspectRatio < 1 ? '8vw' : aspectRatio < 1.6 ? '7.5vw' : '6vw' }} className={`${styles.inner} ${!isDrag && styles.static}`}>
          <MovieMap
            windowWidth={windowWidth}
            aspectRatio={aspectRatio}
            spotlight={spotlight}
            handleHover={handleHover}
            hoverId={hoverId}
            setSpotlight={handleSpotlight}
            isMouse={isMouse}
            isDrag={isDrag}
            movies={movies}
            focusId={focusId}
            styles={styles}
          />
        </div>
      </HorizontalScroll>
    </div>
  );
};

export default PortSlider;
