import React, { useRef, useState, useEffect } from 'react';

import PortSlider from './PortSlider';

import styles from './Portfolio.module.css';

const TitleText = (props) => {
  // adjusting the value of the scroll position for the x pos of about me text
  const scrollPosition = () => {
    let position = -(props.scrollPosition - 50);
    if (position < 0) return 0;
    return position;
  };

  return (
    <>
      <div style={{ transform: `translateX(${scrollPosition()}%)` }} className={styles['title-wrapper']}>
        <h1 className={props.visibility ? styles.blurText : undefined}>
          projekter jeg <br></br>har arbejdet på
        </h1>
      </div>
    </>
  );
};

const Portfolio = (props) => {
  const sectionRef = useRef();
  const [sectionIsVisible, setSectionIsVisible] = useState(false);

  // intersection observer
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setTimeout(() => {
            setSectionIsVisible(true);
          }, 1000);
        } else {
          setSectionIsVisible(false);
        }
      },
      { threshold: 0.5 }
    );
    observer.observe(sectionRef.current);
  }, []);

  return (
    <div ref={sectionRef} className={styles.main}>
      <TitleText visibility={sectionIsVisible} scrollPosition={props.scrollPosition} />
      <div className={`${styles.wrapper} ${sectionIsVisible && styles.active}`}>{<PortSlider aspectRatio={props.aspectRatio} windowWidth={props.windowWidth} isVisible={sectionIsVisible} movies={props.movies} />}</div>
    </div>
  );
};

export default Portfolio;
