import React from 'react';

import styles from './EquipmentPage.module.css';

const EquipmentPage = ({ windowWidth, scrollPosition }) => {
  let position = scrollPosition - 75;

  return (
    <div className={styles.main}>
      <div style={{ marginInline: '8vw auto', height: '8vh', transform: windowWidth > 700 ? `translateY(${position * -30}px)` : undefined }} className={styles.container}>
        <div className={styles.inner} style={{ transform: windowWidth > 700 ? `translateY(${position * 5}px)`: undefined }}>
          <h2>udstyrs udlejning</h2>
        </div>
      </div>
      <div style={{ marginInline: 'auto 15vw', transform: windowWidth > 700 ? `translateY(${position * -30}px)` : undefined}} className={styles.container}>
        <div style={{ marginInline: '2rem 0', transform: windowWidth > 700 ? `translateY(${position * 5}px)` : undefined }} className={styles.inner}>
          <p>
            Lyd udlejning til film, tv, reklamer, og live brug <br /> <br />{' '}
          </p>
          <ul>
            <li>
              <p>- lyd pakker til små og store produktioner</p>
            </li>
            <li>
              <p>- microports med dpa mikrofoner</p>
            </li>
            <li>
              <p>- sound devices lydoptagerer</p>
            </li>
            <li>
              <p>- sennheiser medhørs systemer</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EquipmentPage;
