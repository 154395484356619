import React, { useEffect, useState, useCallback } from 'react';

import styles from './App.module.css';

import LoadingPage from './components/loadingPage/LoadingPage';
import EquipmentPage from './components/equipmentPage/EquipmentPage';
import Header from './components/header/Header';
import LandPage from './components/landPage/LandPage';
import Portfolio from './components/portfolio/Portfolio';
import ContactPage from './components/contactPage/ContactPage';

function App() {
  const [totalPageScroll, setTotalPageScroll] = useState(0);
  const [movies, setMovies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [windowWidth, setWindowWidth] = useState();
  const [aspectRatio, setAspectRatio] = useState(0);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch('./assets/portData.json', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/jsong',
        },
      });
      if (!response.ok) {
        throw new Error('Noget gik lidt galt i kamera afdelingen, prøv at genåbne siden');
      }
      const data = await response.json();

      const loadedMovies = [];

      for (const id in data) {
        loadedMovies.push({
          id: id,
          type: data[id].type,
          title: data[id].title,
          production: data[id].production,
          director: data[id].director,
          role: data[id].role,
          set: data[id].set,
          src: data[id].src,
          web: data[id].web,
        });
      }
      setMovies(loadedMovies);
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  //Set inner width based on window size
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setAspectRatio(window.innerWidth / window.innerHeight);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowWidth, aspectRatio]);

  // setting the scroll percentage value of the screen to totalPageScroll state
  const handleScroll = () => {
    const h = document.documentElement,
      b = document.body,
      st = 'scrollTop',
      sh = 'scrollHeight';

    const totalScrollPercentage = ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;

    setTotalPageScroll(totalScrollPercentage);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {!isLoading ? (
        <>
          <Header aspectRatio={aspectRatio} scrollPosition={totalPageScroll}></Header>
          <section style={{ height: '200vh', scrollSnapAlign: "none" }} id="main-page" className={styles.section}>
            <LandPage aspectRatio={aspectRatio} scrollPosition={totalPageScroll} />
          </section>
          <section style={{height: "100dvh"}} id='portfolio' className={styles.section}>
            <Portfolio aspectRatio={aspectRatio} windowWidth={windowWidth} movies={movies} scrollPosition={totalPageScroll} />
          </section>
          <section style={{height: "100dvh"}} id='equipment' className={styles.section}>
            <EquipmentPage windowWidth={windowWidth} scrollPosition={totalPageScroll} />
          </section>
          <section style={{height: "100dvh"}} id='contact' className={styles.section}>
            <ContactPage />
          </section>
        </>
      ) : (
        <>
          <LoadingPage fetchMessage={error} />
        </>
      )}
    </>
  );
}

export default App;
