import React from 'react';

const PortInfoTxt = ({ focusId, movies, styles, isMouse }) => {
  // let focused = movies.length - +focusId - 1;
  let focused = movies ? movies.length - +focusId - 1 : focusId;

  return (
    <div className={styles['info-container']}>
      {movies[isMouse ? focusId : focused] && (
        <div className={styles['info-inner']}>
          <h5>{movies[isMouse ? focusId : focused].set}</h5>
          <span></span>
          <h5>{movies[isMouse ? focusId : focused].role}</h5>
        </div>
      )}
      <h1>{movies[isMouse ? focusId : focused]?.title || 'portfolio'}</h1>
    </div>
  );
};

export default PortInfoTxt;
