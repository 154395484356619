import React, { useState, useEffect, useRef } from 'react';

import Throttle from '../utility/Throttle';

let scroll;

function HorizontalScroll({ children, setScrollAmount, setIsMouse, isMouse, setDrag, isVisible, windowWidth }) {
  const timeoutRef = useRef(null);
  const [windowMiddle, setWindowMiddle] = useState();

  const [isDragging, setIsDragging] = useState(false);
  const [isTouch, setIsTouch] = useState(false);
  const [startX, setStartX] = useState(0);
  const [xPos, setXPos] = useState(0);

  const [exponent, setExponent] = useState(0);
  const [expoFactor, setExpoFactor] = useState(0.01);

  // set WindowMiddle
  useEffect(() => {
    setWindowMiddle(windowWidth / 2);
    scroll = 0;
  }, [windowWidth]);

  // scrollValue
  useEffect(() => {
    let expoId = null;

    const setScrollAmountProp = (value) => {
      if (typeof setScrollAmount === 'function') {
        setScrollAmount(value);
      }
    };

    if (isDragging) {
      // Scroll amount increaser
      expoId = setInterval(() => {
        setExpoFactor(0.012 * Math.pow(2, exponent));
        setExponent((prevState) => (prevState + Math.abs(scroll) < 30 ? 0.09 : 0.006));

        scroll = scroll + xPos * expoFactor;
        setScrollAmountProp(scroll);

        if (exponent >= 4 || !isDragging) {
          clearInterval(expoId);
        }
      }, 5);
    }
    return () => {
      if (!isDragging) {
        scroll = 0;
        setScrollAmountProp(0);
        setExponent(0);
      }
      clearInterval(expoId);
    };
  }, [isDragging, xPos, expoFactor, exponent, setScrollAmount]);

  const setIsDrag = (e) => {
    setDrag(e);
  };

  // Mouse Handling
  const onMouseDown = (event) => {
    if (!isVisible || isDragging || event.button !== 0) return;
    !isMouse && !isTouch && setIsMouse(true);

    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setIsTouch(false);
      setIsDragging(true);
      setIsDrag(true);
      const xPos = event.pageX;
      setStartX(xPos);
    }, 50);
  };

  const onMouseMove = Throttle((event) => {
    if (!isDragging) return;
    const x = event.pageX;
    let pos = (x - startX) / 8;
    if (x < 0 || x > windowMiddle * 2) return;
    if (pos > 90 || pos < -90) return;
    setXPos(pos * 1.5);
  }, 10);

  const onMouseUp = () => {
    clearTimeout(timeoutRef.current);
    setXPos(0);
    setIsDragging(false);
    setIsDrag(false);
  };

  // Touch Handling

  const onTouchStart = (event) => {
    if (!isVisible) return;
    if (event.type === 'mousedown') return;
    isMouse && setIsMouse(false);

    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      setIsTouch(true);
      setIsDragging(true);
      setIsDrag(true);
      const xPos = event.touches[0].pageX;
      setStartX(xPos);
    }, 100);
  };

  const onTouchMove = Throttle((event) => {
    if (!isDragging) return;
    const x = event.touches[0].pageX;
    if (x < 0 || x > windowMiddle * 2) return;
    setXPos(x - startX);
  }, 5);

  const onTouchEnd = () => {
    clearTimeout(timeoutRef.current);
    setIsDragging(false);
    setIsDrag(false);
  };

  return (
    <div
      style={{ position: 'relative', flexGrow: '1' }}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      onTouchCancel={onTouchEnd}
    >
      {children}
    </div>
  );
}

export default HorizontalScroll;
