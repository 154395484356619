import React, { useEffect, useState } from 'react';
import styles from './ContactPage.module.css';

const ContactPage = () => {
  const [txtCount, setTxtCount] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (txtCount === 2) return setTxtCount(0);
      setTxtCount((prevCount) => {
        return prevCount + 1;
      });
    }, 2000);
    return () => clearTimeout(timer);
  }, [txtCount]);

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <aside>
          <div className={styles.asideHeader}>
            <h2>Lad os</h2>
            <div className={styles.txtWrapper}>
              <h1 className={txtCount === 0 ? styles.txtActive : undefined}>mødes</h1>
              <h1 className={txtCount === 1 ? styles.txtActive : undefined}>ringes</h1>
              <h1 className={txtCount === 2 ? styles.txtActive : undefined}>skrives</h1>
            </div>
          </div>
          <h3>
            og snakke om <br></br> jeres projekt
          </h3>
        </aside>
        <section>
          <h2 style={{ paddingLeft: '1.5rem', cursor: 'auto' }}>emil woxen andersen</h2>
          <h2>
            <a target="_blank" className={styles.hoverfx} href="mailto: emilwoxen@gmail.com">
              emilwoxen@gmail.com
            </a>
          </h2>
          <h2>
            <a target="_blank" href="tel:+4522467633">
              +45 22 46 76 33
            </a>
          </h2>
          <h2>
            <a target="_blank" href="https://www.instagram.com/woxensound/">
              @woxensound
            </a>
          </h2>
        </section>
      </div>
      <footer>
        <p>www.woxensound.com, Richard Mortensens Vej 57, 2 tv., 2300 København S - Copyright © Alle rettigheder forbeholdes</p>
      </footer>
    </div>
  );
};

export default ContactPage;
