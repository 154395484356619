import React, { useMemo, useCallback, useEffect, useRef } from 'react';


import MovieItem from './MovieItem';

import playIcon from './../../assets/images/icons/playIcon.png';

const MovieArray = (props) => {
  const dragRef = useRef(false);
  const observerRef = useRef();

  const movieArray = useMemo(() => props.movies.slice(0).reverse(), [props.movies]);
  let focused = props.movies.length - +props.focusId - 1;

  useEffect(() => {
    dragRef.current = props.isDrag;
  }, [props.isDrag]);

  function bottomCalc(i) {
    let a = Math.abs(i - focused);
    let b;
    if (a < 4) {
      b = a * 35;
    }
    return b;
  }

  // onClick event
  const spotlightHandle = (i) => {
    setTimeout(() => {
      if (dragRef.current) return;
      props.setSpotlight(i);
    }, 100);
  };

  // hover event
  const hoverHandle = useCallback(
    (i) => {
      if (props.isMouse) {
        return !props.spotlight && i === props.hoverId && props.styles.movieFocused;
      } else if (!props.isMouse && !props.spotlight && i === focused) {
        return props.styles.movieFocused;
      }
    },
    [props.isMouse, props.spotlight, props.hoverId, focused, props.styles.movieFocused]
  );

  return (
    <>
      {movieArray.map((item) => (
        <div
          onMouseDown={() => props.isMouse && spotlightHandle(item.id)}
          onClick={() => !props.isMouse && spotlightHandle(item.id)}
          onMouseEnter={(event) => props.isMouse && props.handleHover(+item.id, event)}
          onMouseLeave={(event) => props.isMouse && props.handleHover(+item.id, event)}
          className={`${props.styles.movieItem} ${hoverHandle(+item.id)}`}
          style={{ width: props.aspectRatio < 1 ? '25vw' : props.aspectRatio < 1.6 ? '12vw' : '8vw', marginBottom: -bottomCalc(item.id) + 'px' }}
          key={item.id}
        >
          {item.web && <img className={`${props.styles.playImg} ${!props.isMouse && +item.id === focused && props.styles.playImgFocused}`} src={playIcon} alt="movie can be played" />}
          <MovieItem windowWidth={props.windowWidth} src={item.src} style={props.styles.posterImg} />
        </div>
      ))}
    </>
  );
};

export default MovieArray;
