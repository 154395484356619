import React, { useState } from 'react';

// Images
import arrowHead from '../../assets/images/icons/cursorIconPlay.png';
import arrowLines from '../../assets/images/icons/cursorLinesPlay.png';

const Overlay = ({ styles }) => {
  const [modal, setModal] = useState(true);

  const modalHandler = () => {
    setModal(false);
  };

  return (
    <div onClick={modalHandler} style={{ display: modal ? 'flex' : 'none' }} className={styles.modal}>
      <div className={styles.modalInner}>
        <img className={styles.arrowHead} src={arrowHead} alt="cursor head for guidance" />
        <img className={styles.arrowLines} src={arrowLines} alt="cursor movement lines for guidance" />
      </div>
    </div>
  );
};

export default Overlay;
