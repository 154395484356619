import React, { useState, useEffect } from "react";

import Logo from "../../assets/images/logo/LOGO_ws1.svg";

import styles from "./LoadingPage.module.css";
const LoadingPage = (props) => {
  const [loadingDots, setLoadingDots] = useState(0);
  const [message, setMessage] = useState();

  function loadingIncrement() {
    if (loadingDots < 3) {
      setLoadingDots(loadingDots + 1);
    } else {
      setLoadingDots(0);
    }
  }

  useEffect(() => {
    let timer1 = setTimeout(() => loadingIncrement(), 1000);
    setMessage(props.fetchMessage);

    return () => {
      clearTimeout(timer1);
    };
  }, [loadingDots, props.fetchMessage]);

  return (
    <main className={styles.main}>
      <div className={styles.container}>
        <img src={Logo} alt="Woxensound" />
        {!message ? (
          <h5>
            Kamera afdelingen er lidt langsomme idag, vent venligst{" "}
            {".".repeat(loadingDots)}
          </h5>
        ) : (
          <h5>{message}</h5>
        )}
      </div>
    </main>
  );
};

export default LoadingPage;
