import React from 'react';

import logoLine from '../../assets/images/logo/logo_line.png';
import styles from './Header.module.css';

const Header = (props) => {
  function scrollPosition() {
    let position = (props.scrollPosition * 3.2) /(props.aspectRatio+1.7);
    return position;
  }

  return (
    <div className={`${styles.header} ${scrollPosition() >= 64 && styles.headerMid}`}>
      <div className={styles['line-container']}>
        <div style={{ transform: `translateX(${-scrollPosition() / 3.5}%)` }} className={styles['line-inner']}>
          <img className={styles.logoLine} src={logoLine} alt="Woxensound Logo" />
          <span style={{ width: `${scrollPosition()}%` }} className={styles.span}></span>
        </div>
      </div>
      <header className={styles.inner}>
        <ul className={styles.ul}>
          <li>
            <a className={styles.a} href="#portfolio">
              portfolio
            </a>
          </li>
          <li>
            <a className={styles.a} href="#equipment">
              udstyr
            </a>
          </li>
          <li>
            <a className={styles.a} href="#contact">
              kontakt
            </a>
          </li>
        </ul>
      </header>
    </div>
  );
};

export default Header;
