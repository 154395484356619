let throttleWait = false;

export default function Throttle(cb, delay = 200) {
  return (...args) => {
    if (!args || !args.length || throttleWait) return;
    if (throttleWait) return;

    cb(...args);
    throttleWait = true;

    setTimeout(() => {
      throttleWait = false;
    }, delay);
  };
}
