import React from 'react';

import playImg from '../../assets/images/icons/playIcon.png';

const Spotlight = ({ styles, movie, setSpotlight, isActive}) => {
  return (
    <div onClick={setSpotlight} className={`${styles.spotContainer} ${isActive && styles.spotActive}`}>
      {isActive && <img className={styles.moviePoster} alt={'Focused Film'} src={movie.src + ".png"} />}
      {isActive && (
        <div className={styles.spotInner}>
          <h2>
            {movie.title} ({movie.type})
          </h2>
          <h3>
            {movie.role} ({movie.set})
          </h3>
          {movie.director && <h3>instrukør: {movie.director}</h3>}
          {movie.production && <h3>produktion: {movie.production}</h3>}
          {movie.web && (
            <a href={movie.web} className={styles.spotPlayWrapper} target="_blank">
              <img src={playImg} alt="Movie is playable" />
              <div className={styles.spoth5Wrapper}>
                <h5>se filmen her</h5>
              </div>
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default Spotlight;
